import React from 'react';
import { Style } from '@glitz/type';
import { styled, StyledProps } from '@glitz/react';
import { AppearanceType } from '../appearance';
import * as style from 'Shared/Style';
import Alert from 'Shared/Icon/Alert';
import {
  responsiveMargin,
  pixelsToUnit,
  Margin,
  General,
  general,
  transition,
  truncate,
  negativeLight,
  monochromeDark,
  monochromeLight,
  negative,
  baseTextColor,
} from '../Style';

export { General as Variant } from '../Style';
export enum Appearance {
  Bare,
}
type PlaceholderPropType = {
  variant?: General;
  lifted?: boolean;
  invalid?: boolean;
  required?: boolean;
};
const Label: React.StatelessComponent<PlaceholderPropType> = props => {
  const { variant = General.Default } = props;
  return (
    <styled.Span
      css={{
        position: 'relative',
        top: 5,
        left: 5,
        fontWeight: 300,
        pointerEvents: 'none',
        transformOrigin: 'left top',
        color: '#757575',
        ...general({ type: variant, horizontalPadding: 0 }),
        ...transition({ property: ['color', 'padding', 'transform'], timingFunction: 'ease-in-out' }),
        ...truncate(),
        ...(props.lifted && {
          color: baseTextColor,
          padding: { xy: 0 },
          transform: 'scale(.5)',
        }),
        ...(props.required && {
          fontWeight: 'bold',
        }),
        ...(props.invalid && {
          color: negativeLight,
        }),
      }}
    />
  );
};

type BareInputPropType = StyledProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    elementRef?: React.Ref<HTMLInputElement>;
  };
export const BareInput = styled(
  class extends React.Component<BareInputPropType> {
    inputStyle: Style = {
      width: '100%',
      marginBottom: 0,
      height: 39,
      padding: {
        top: 10,
        bottom: 10,
        left: 12,
      },
      border: {
        xy: {
          style: 'solid',
          color: style.tertiaryLightest,
          width: 1,
        },
      },
      ':invalid': {
        boxShadow: 'none',
      },
    };
    render() {
      const { compose, elementRef, ...restProps } = this.props;
      return <styled.Input {...restProps} innerRef={elementRef} css={compose(this.inputStyle)} />;
    }
  },
);

type InputTextType =
  | 'date'
  | 'datetime'
  | 'datetime-local'
  | 'email'
  | 'month'
  | 'number'
  | 'password'
  | 'range'
  | 'search'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

type PropType = StyledProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    type?: InputTextType;
    variant?: General;
    appearance?: AppearanceType<Appearance>;
    elementRef?: (el: HTMLInputElement) => void;
  };

type Value = string | number | string[];

type StateType = {
  focused?: boolean;
  filled?: boolean;
  invalid?: boolean;
};

function isFilled(value: Value) {
  return !!value && (Array.isArray(value) ? value.length > 0 : /[\S]/.test(`${value}`));
}

const Text = styled(
  class Input extends React.Component<PropType, StateType> {
    el: HTMLInputElement;
    constructor(props: PropType) {
      super(props);
      this.state = {
        focused: false,
        filled: isFilled(props.value),
        invalid: false,
      };
    }
    setValidity(el: HTMLInputElement) {
      const invalid = !el.validity.valid;
      if (invalid !== this.state.invalid) {
        this.setState({ invalid });
      }
    }
    onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (this.props.onFocus) {
        this.props.onFocus(e);
      }

      this.setState({ focused: true });
    };
    onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (this.props.onBlur) {
        this.props.onBlur(e);
      }

      this.setValidity(e.currentTarget);
      this.setState({ focused: false });
    };
    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (this.props.onChange) {
        this.props.onChange(e);
      }

      const el = e.currentTarget;
      this.setValidity(el);

      const filled = isFilled(el.value);
      if (filled !== this.state.filled) {
        this.setState({ filled });
      }
    };
    getStatusColor() {
      if (this.state.invalid) {
        return negative;
      }
      if (this.props.readOnly) {
        return 'transparent';
      }
      if (this.state.focused || this.state.filled) {
        return monochromeDark;
      }
      return monochromeLight;
    }
    ref = (el: HTMLInputElement) => {
      if (this.props.elementRef) {
        this.props.elementRef(el);
      }

      this.el = el;
    };
    render() {
      const {
        compose,
        size,
        appearance,
        variant,
        placeholder,
        children,
        className,
        style: styleProp,
        ...restProps
      } = this.props;

      const realPlaceholder = placeholder || children;

      return (
        <styled.Label style={styleProp} css={compose(this.labelStyle())}>
          {realPlaceholder && (
            <Label
              variant={variant}
              lifted={this.props.readOnly || this.state.focused || this.state.filled}
              invalid={this.state.invalid}
              required={this.props.required}
            >
              {realPlaceholder}
              {this.state.invalid && <Invalid />}
            </Label>
          )}
          <BareInput {...restProps} placeholder={this.props.placeholder} />
        </styled.Label>
      );
    }
    labelStyle = (): Style => ({});
  },
);

export default Text;

const Invalid = styled(Alert, {});

export const Fields = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

export const FullText = styled(Text, {
  width: '100%',
});

const GUTTER = Margin.Small;

export const HalfText = styled(Text, {
  ...responsiveMargin(margin => ({
    width: `calc(50% - ${pixelsToUnit(margin(GUTTER))} / 2)`,
  })),
});
